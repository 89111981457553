import MDBox from "../MDBox";
import { Icon, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import MDTypography from "../MDTypography";
import { useEffect, useState } from "react";
import _WorkSpace from "../../_Models/_WorkSpace";
import TableContainer from "@mui/material/TableContainer";
import DataTableHeadCell from "../../examples/Tables/DataTable/DataTableHeadCell";
import MDButton from "../MDButton";
import { setCurrentWorSpace, useDataController } from "../../context/data/dataContext";
import {
  PushNotification,
  setBackDrop,
  setIntroSteps,
  useMaterialUIController,
} from "../../context/theme/themeContext";
import Intro from "../../Intro/Intro";

const WorkSpaceSelector = ({}) => {

  let [workSpaces, setWorkSpaces] = useState([])
  let [render, setRender] = useState(false)
  let [newWorkSpace, setNewWorkSpace] = useState(null)
  const [controller, dispatch] = useMaterialUIController();
  const [dataController, dataDispatch] = useDataController();
  const {workSpace} = dataController

  let sx = {
    position: 'absolute',
    width: '60vw', height: '60vh', ml: '20%', mt: '10%', p:4,
    backgroundColor: '#FFFFFF'
  }

  useEffect(()=>{
    _WorkSpace.getAll(0, 10).then(getAllOp=>{
      getAllOp.json().then(workSpaces=>{
        setWorkSpaces(workSpaces)
      })
    })

  }, [render])


  function help(){
    setIntroSteps(dispatch, Intro.WorkSpacesTable)
  }


  function createNewWorkSpace(e){
    e.preventDefault()
    _WorkSpace.create(newWorkSpace).then(r=>{
      if(r.status == '201'){
        PushNotification(dispatch, 'WorkSpace created correctly', 'success', 2000)
        setRender(true)
      }
    })
  }

  return<MDBox sx={sx}>
      <Stack spacing={2}>
        <MDBox display={'flex'} justifyContent={'space-between'} sx={{backgroundColor: ''}}>
          <MDTypography variant={'h4'} fontWeight={"bold"}>Select a work space</MDTypography>
          <MDBox>
            <MDButton variant={"contained"} color={"dark"} sx={{mr:2}} id={'addWorkSpace'} onClick={()=>{setNewWorkSpace({})}}><Icon>add</Icon>New  workspace</MDButton>
            <MDButton variant={"contained"} color={"info"} onClick={help}><Icon>help</Icon>Help</MDButton>
          </MDBox>

        </MDBox>
        {/*<MDTypography>Work Spaces are  container of systems under consideration </MDTypography>*/}
        {/*<MDTypography>Start by selecting a workspace</MDTypography>*/}
        <TableContainer>
          <Table aria-label="collapsible table" id={'workSpacesTable'}>
            <TableHead>
              <DataTableHeadCell></DataTableHeadCell>
              <DataTableHeadCell>Id</DataTableHeadCell>
              <DataTableHeadCell>Name</DataTableHeadCell>
            </TableHead>
            <TableBody id={'workSpacesTableBody'}>
              {
                newWorkSpace !== null &&
                  <TableRow>
                    <TableCell colSpan={3} sx={{width: '80%'}}>
                      <MDBox    component="form" role="form" onSubmit={createNewWorkSpace} sx={{display:'flex'}}>
                        <TextField
                          fullWidth={true} label={'Work Space Name'} size={"small"} required={true} sx={{mr:4}} name={'name'} value={newWorkSpace['name']}
                          onChange={(e)=>{
                            newWorkSpace['name'] = e.target.value
                            setNewWorkSpace(newWorkSpace)
                          }}
                        />
                        <MDButton variant={"contained"} color={"info"} sx={{mr:2}} size={"small"} type={'submit'} >Submit</MDButton>
                        <MDButton variant={"contained"} color={"error"} size={"small"} onClick={()=>{setNewWorkSpace(null)}}>Cancel</MDButton>
                      </MDBox>
                    </TableCell>
                  </TableRow>
              }
              {
               workSpaces.length > 0
                ?  workSpaces.map(currentWorkspace=>{
                   return <TableRow key={currentWorkspace.id} sx={{
                     cursor: 'pointer',
                     backgroundColor: '',
                     padding:0,
                     fontSize: '12px',
                     '&:hover':{
                       backgroundColor: 'lightgrey'
                     }
                   }}
                                    onClick={()=>{setCurrentWorSpace(dataDispatch, currentWorkspace)}}
                   >
                     <TableCell sx={{backgroundColor: '', p:1}}>
                       {
                         workSpace.id == currentWorkspace.id && <Icon sx={{mr:1}} color={'info'}>check</Icon>
                       }

                       <Icon>delete</Icon>
                     </TableCell>
                     <TableCell sx={{fontSize: '14px', backgroundColor: '', p:1}}>{currentWorkspace.id}</TableCell>
                     <TableCell sx={{fontSize: '14px', backgroundColor: '', p:1}}>{currentWorkspace.name}</TableCell>
                   </TableRow>
                 })
                 : <TableRow>
                   <DataTableHeadCell></DataTableHeadCell>
                   <TableCell colSpan={2}><Icon>info</Icon>No Work spaces found</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <MDButton variant={"contained"} color={"light"}  sx={{backgroundColor: '', position: 'absolute', bottom: '10px', right: 32}} onClick={()=>{
        if(workSpace.id == undefined){
          PushNotification(dispatch, 'Please select a work space to proceed', 'error', 3000 )
          return
        }
        setBackDrop(dispatch, false)}
      }>Close</MDButton>
    </MDBox>
}

export default WorkSpaceSelector
