import { useEffect, useState } from "react";

import { Paper } from "@mui/material";
import { Chart } from "react-google-charts";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

const CharTitle = ({title, subtitle})=>{

  return(
    <MDBox display="flex" justifyContent="space-between"  pt={1} px={2} flexDirection={'column'}>
      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">{title}</MDTypography>
      <MDTypography textTransform="capitalize" color={"text"} sx={{fontSize: '14px'}}>{subtitle}</MDTypography>
    </MDBox>
  )
}

const CIFDistributionGraph = ({cifD}) => {
  const [cifDistribution, setCifDistribution] = useState([ ['', "Safety", "Availability", "Business", "Confidentiality"], [" ", 22, 20, 13, 15]])


  useEffect(()=>{

    if(cifD !== null){
      if(cifD !== undefined){
        let names = cifD.map(entry=>{
          return entry.name
        })
        let values = cifD.map(entry=>{
          return entry.total
        })
        setCifDistribution([ [' '].concat(names), [' '].concat(values)])
      }
    }

  }, [cifD])

  return <Chart
      chartType="Bar"
      width="650px"
      height="350px"
      data={cifDistribution}
      options={{ chart: {}, }}
    />

}

export default CIFDistributionGraph
