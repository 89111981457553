import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { useEffect, useMemo, useRef, useState } from "react";
import MDButton from "../../../../components/MDButton";
import { setBackDrop, useMaterialUIController, } from "context/theme/themeContext";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import MDToggleButton from "../../../../components/MDToggleButton";
import NewAssessment from "../../../../components/NewAssessment/NewAssesment";
import { CURRENT_SYSTEM } from "../../../../assets/StorageItems";
import _ThreatEntry from "../../../../_Models/_ThreatEntry";
import useFetch from "../../../../Apis/useFetch";
import UI_STATUS from "../../../../components/LodingProvider/UiStatus";
import { FETCH_STATUS } from "../../../../assets/HttpResponses";
import ThreatRowActions from "./components/ThreatRowActions/ThreatRowActions";
import NewInLineAssessment from "../../../../components/NewInLineAssessment/NewInLineAssessment";
import MDBox from "../../../../components/MDBox";
import { useDataController } from "../../../../context/data/dataContext";

function ChildSelector(props) {
  return <Tabs orientation={"horizontal"} value={props.value} onChange={props.onChange}>
    <Tab sx={{ mx: 2 }} label="Systems" icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>home</Icon>} />
    <Tab sx={{ mr: 2 }} label="Zones" icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>email</Icon>} />
    <Tab sx={{ mr: 2 }} label="Pipes" icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>settings</Icon>} />
  </Tabs>;
}

ChildSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

const ThreatRow = ({ threat, nextThreat }) => {
  const [controller, dispatch] = useMaterialUIController();
  const [threatEntry, setThreatEntry] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const actionsRef = useRef(null);
  const [dataController, dataDispatch] = useDataController()
  let {currentGlobalSystem} = dataController

  useEffect(()=>{
    if(currentGlobalSystem.id !== undefined){

      _ThreatEntry.getOne(currentGlobalSystem.id, threat.id)
        .then(r=>{
          if(r.status == FETCH_STATUS.OK){
            r.json()
              .then(r=>{
                setThreatEntry(r)

              }).catch(r=>{
            })
          }else {

          }
        })
        .catch(e=>{
          console.log(e)
        })
    }

  }, [threat, currentGlobalSystem])

 if(threat !== undefined){
   return (
     <>
       <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} sx={{ backgroundColor: '', width: '100%' }} onDoubleClick={(e)=>{
         setOpen(!open)
       }}>
         <TableCell component="th" scope="row">{threat.name}</TableCell>
         <TableCell component="th" scope="row">{threat['Origin']}</TableCell>
         <TableCell scope="row" align={'right'}>
           {
             open
               ?<MDBox display={'flex'}  sx={{backgroundColor: '', alignItems: 'center', justifyContent: 'flex-end'}}>
               <MDButton variant="gradient" color="info" fullWidth onClick={()=>{
                 actionsRef.current.handleSubmit()
               }} sx={{width: '30%', mr:3}}>Save</MDButton>
               <MDButton variant="gradient" color="error" fullWidth  sx={{width: '20%'}} onClick={()=>{setOpen(false)}}>Cancel</MDButton>
               </MDBox>
               :<ThreatRowActions threat={threat} nextThreat={nextThreat} threatEntry={threatEntry}></ThreatRowActions>
           }

         </TableCell>
       </TableRow>
       {
         open &&
         <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} sx={{ backgroundColor: '', width: '100%' }} >
           <TableCell colSpan={3}>   <NewInLineAssessment threat={threat} ref={actionsRef} threatEntry={threatEntry}></NewInLineAssessment></TableCell>
         </TableRow>
       }

     </>
   );
 }
};
ThreatRow.propTypes = { threat: PropTypes.shape({}).isRequired, };

export default ThreatRow
