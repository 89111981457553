const ProjectsTableIntro  = [
  {
    element: "#ProjectsContainer",
    intro: "Systems under consideration management"
  },
  {
    element: "#projectRow",
    intro: "Every line represent a system"
  },
  {
    element: "#projectExpander",
    intro: "Expand the row for Systems details"
  },
]

export default ProjectsTableIntro
