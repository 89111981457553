const TopNavIntro = [
  // {
  //   element: "#lk",
  //   intro: <Comp/>,
  // },
  {
    element: "#navContainer",
    intro: 'This is your navigation bar'
  },
  {
    element: "#locationIndicator",
    intro: "Find exactly where you are"
  },
  {
    element: "#TopMenu",
    intro: "The left menu let you toggle primary configs of the system and check your profile"
  },
]
export default TopNavIntro
