import { Box, CircularProgress, Divider, Grid, Paper, Stack } from "@mui/material";

import MDBox from "../../../../components/MDBox";
import FileViewer from 'react-file-viewer';
import Switch from "@mui/material/Switch";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { setBackDrop, useMaterialUIController } from "../../../../context/theme/themeContext";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { TemplateHandler } from "easy-template-x";
import { useEffect, useState } from "react";
import MSDocRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/msdoc";
import PDFRenderer from "@cyntler/react-doc-viewer/dist/cjs/plugins/pdf";
import * as ReactDOMServer from 'react-dom/server';
import saveFile from "../../../../Apis/SaveFile";
import _Project from "../../../../_Models/_Project";
import UiStatus from "../../../../components/LodingProvider/UiStatus";
import projectDetails from "../../../ProjectDetails/ProjectDetails";
import html2canvas from "html2canvas";
import docxGenerator from "../../../../Apis/DocxGenerator";
import ImageReplacer from "../../../../Apis/ImageReplacer";
import image from '../../../../assets/images/apple-icon.png'
import SystemStats from "../../../SystemDetails/components/SystemsStats/SystemStats";
import _System from "../../../../_Models/_System";
import { setCurrentGlobalSystem } from "../../../../context/data/dataContext";
import UI_STATUS from "../../../../components/LodingProvider/UiStatus";
import CIFDistributionGraph from "../../../../components/CIFDistributionGraph/CIFDistributionGraph";
import ActorDistributionGraph from "../../../../components/ActorDistributionGraph/ActorDistributionGraph";
import ApplicabilityGraph from "../../../../components/ApplicabilityGraph/ApplicabilityGraph";
import { getFRS } from "../../../../assets/StorageItems";
import _User from "../../../../_Models/_User";


const ReportSwitch = ({text, checked, name, onChange})=>{
  return           <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
    <MDBox mt={0.5}>
      <Switch  name={name} checked={checked} onChange={onChange}/>
    </MDBox>
    <MDBox width="80%" ml={0.5}>
      <MDTypography variant="button" fontWeight="regular" color="text">
        {text}
      </MDTypography>
    </MDBox>
  </MDBox>
}



const ProjectReport = ({project}) => {

  const [controller, dispatch] = useMaterialUIController();
  const [system, setSystem] = useState(null);
  const [ready, setReady] = useState(false);
  const [render, setRender] = useState(false);
  const [working, setWorking] = useState(false);
  const [data, setData] = useState(null);
  const [systemImages, setSystemsImages] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [systems, setSystems] = useState([]);
  const [options, setOptions] = useState({
    SysDesc: true,
    zones: true,
    conduits: true,
    subSystems: true,
    treatmentData:true, cifDist:true,   applicabilityDist:false, actorDist:true});
  const FRs = getFRS()

  function getMaxByFR(system) {
    let FRs = getFRS()

    for (let fr of FRs) {
      fr['max'] = 0
    }

    for (let threatEntry of system['threatEntries']) {
      if (threatEntry['applicability']) {
        let risk = getRisk(threatEntry['exposure'], threatEntry['vulnerability'], threatEntry['cIFValue']['value'])
        console.log('risk' + risk)
        let threatEntryFr = threatEntry['threat']['fr']
        let index = FRs.findIndex(entry => entry.id == threatEntryFr['id'])
        console.log('index ' + index)
        if (risk > FRs[index]['max']) FRs[index]['max'] = risk
        console.log(FRs[index])
      }
    }

    let result = FRs.map(entry => {
      return { name: entry.name, max: entry.max }
    })
    return result
  }

  function getZoneFrs(zone){
    let zoneFrs = FRs.map(entry=>{
      return {name: entry.name, max:9}
    })
  }

  function getRisk(exposure, vulnerability, impact){
    let  likelihood = getLikelihood(exposure, vulnerability)
    console.log(likelihood + ' : likelihood')
    if(likelihood == 1 || impact == 1) return 1
    switch (impact){
      case 2:
        if(likelihood < 4){
          return 1
        }
        return 2
        break;
      case 3:
        if(likelihood < 3) return 1
        if(likelihood == 5) return 3
        return 2
        break;
      case 4:
        if(likelihood == 1) return 1
        if(likelihood == 2 || likelihood == 3) return 2
        return likelihood - 1
        break;
      case 5:
        if(likelihood < 4) return likelihood
        return 4
        break;
    }

    return


  }

  function getLikelihood(exposure, vulnerability) {
    return exposure + vulnerability - 1
  }

 function onChange(e){
   const {name, checked} = e.target
   options[name] = checked
   setRender(!render)
   reset()
 }

  useEffect(()=>{
    if(project !== undefined){
      _Project.getReportData(project.id)
        .then(op=>{
          op.json()
            .then(project=>{
              setReportData(project)
              console.log(project)
            })
        })
        .catch(e=>{

        })
    }
  }, [project])

  function reset(){
    setWorking(false)
    setReady(false)
    setSystemsImages([])
    setSystems([])
  }

  async function prepareTheDocument(project) {
    setWorking(true)
    setSystems([])
    let user = JSON.parse(localStorage.getItem('user'))

    const data = {
      userName: user.name,
      SysDesc: options.SysDesc,
      ShowZones: options.zones,
      ShowConduits: options.conduits,
      ShowSubSystems: options.subSystems,
      ThreatEntriesShow: false,
      "projectName": project.name,
      "projectDesc": project.description,
      "SubSystems": [],
      "Zones": [],
      "Conduits": [],
      "Rzones": [],
    };


    for (let system of project.systems) {
      data.SubSystems.push({ "name": system.name, "createdAt": system.createdAt.substring(0, 10) })
    }

    for (let zone of project.zones) {
      let zoneInfo = { "name": zone.name, "createdAt": zone.createdAt.substring(0, 10), fr1:0, fr2:0, fr3:0, fr4:0, fr5:0, fr6:0, fr7:0, frZonesSystems: [] }

      let tempSystems = []
      let frZonesSystems = []

      for (const system of zone["systems"]) {

        let getSystem = await _System.getOne(system.id)
        let systemm = await getSystem.json()
        const currentSystemFrs = getMaxByFR(system)
        let t = {}
        for (let i = 0; i < currentSystemFrs.length; i++){
          const entry = currentSystemFrs[i];
          t[i+1] = entry.max
          let j = i+1
          let d = 'fr'+j

          if(parseInt(entry.max) > parseInt(zoneInfo[d])){zoneInfo[d] = entry.max}
        }
        zoneInfo.frZonesSystems.push({name: system.name, ...(t) })


        systems.push(systemm)
        setSystems(systems)
        //prepare threat entries data
        let threatEntries = []
        system.threatEntries.forEach(threatEntry=>{
          let CIFs = ''
          threatEntry.CIFs.forEach(entry=>{
            CIFs = CIFs + entry.CIF.name + ', '
          })

          let Actors = ''
          threatEntry.Actors.forEach(entry=>{

            Actors = Actors + entry.actor.name + ', '

          })

          let Components = ''
          threatEntry.affectedComponents.forEach(comp=>{
            Components = Components + comp.component.name + ', '

          })
          threatEntries.push({
            threatName: threatEntry.threat.name,
            threatType: threatEntry.threat.threatType.name,
            threatOrigins: threatEntry.threat.Origin,
            ttp1: threatEntry.TTP1,
            ttp2: threatEntry.TTP2,
            actor:Actors,
            CIF: CIFs,
            IV: threatEntry.cIFValue.name,

            affectedComponents: Components,
            exposure: threatEntry.exposure,
            vulnerability: threatEntry.vulnerability,
            comment: threatEntry.description,
          })
        })
        tempSystems.push({
          name: system.name,
          ThreatEntriesShow: options.applicabilityDist,
          ThreatEntries: threatEntries,
          cifDist: options.cifDist,
          actorsDist: options.actorDist,
          showAppGraph: true,
          statsGraph: {
            _type: "image",
            source: '',
            format: 'image/jpeg',
            altText: "Kung Fu Hero", // Optional
            width: 600,
            height: 380
          },
          actorGraph: {
            _type: "image",
            source: '',
            format: 'image/jpeg',
            altText: "Kung Fu Hero", // Optional
            width: 600,
            height: 400
          },
          AppGraph: {
            _type: "image",
            source: '',
            format: 'image/jpeg',
            altText: "Kung Fu Hero", // Optional
            width: 600,
            height: 400
          }
        })

      }
      data.Zones.push(zoneInfo)
      data.Rzones.push(
        {
          name: zone.name,
          ZsubSystems: tempSystems,
        }
      )
    }

    for (let conduit of project.conduits) {
      data.Conduits.push({ "name": conduit.name, 'from': conduit.fromZone.name, 'to': conduit.toZone.name, "createdAt": conduit.createdAt.substring(0, 10) })
    }
    setReady(true)
    setRender(!render)
    setData(data)
    console.log(data)
    setWorking(false)
    return true


  }

  async function renderStatGraphs(){
    for (const system1 of systems) {
      let id  = '#systemCIF' + system1.id
      let canvas = await html2canvas(document.querySelector(id), { backgroundColor: 'white' })

      canvas.toBlob(async (CIFGraph) => {
        let id  = '#systemActor' + system1.id
        let canvas2 = await html2canvas(document.querySelector(id), { backgroundColor: 'white' })

        canvas2.toBlob(async (ActorGraph) => {
          let id  = '#systemApp' + system1.id
          let canvas3 = await html2canvas(document.querySelector(id), { backgroundColor: 'white' })
          canvas3.toBlob(async (AppGraph) => {
            systemImages.push({cif: CIFGraph, actor: ActorGraph, app: AppGraph})
            console.log('*********************************************')
            console.log(AppGraph)
            setRender(!render)

          }, 'image/jpeg', 1)


        }, 'image/jpeg', 1)

      }, 'image/jpeg', 1)


    }
    return true
  }

  async function doExport() {

    const response = await fetch('ProjectReportttt.docx');
    const templateFile = await response.blob();
    const handler = new TemplateHandler();
    // const doc = await handler.process(templateFile, data);
    // saveFile(project.name + ' Risk assessment report.docx', doc);
  }


  useEffect( () => {

  }, [reportData, systemImages])


  return (
    <Paper sx={{p:4, ml: '20%', width: '50vw'}}>
      <MDBox id={'statsBox'} sx={{position: 'absolute', top: '100%'}}>
        <Stack>
          {
            systems.map(system=>{
              return <MDBox id={'systemCIF'+system.id} key={'systemCIF'+system.id}>
                <CIFDistributionGraph  cifD={system['CIFsDistribution']}></CIFDistributionGraph>
              </MDBox>

            })
          }
        </Stack>
      </MDBox>
      <MDBox id={'statsBox'} sx={{position: 'absolute', top: '100%'}}>
        <Stack>
          {
            systems.map(system=>{
              return <MDBox id={'systemActor'+system.id} key={'systemActor'+system.id}>
                <ActorDistributionGraph  actorsD={system['actorsDistribution']}></ActorDistributionGraph>
              </MDBox>

            })
          }
        </Stack>
      </MDBox>
      <MDBox id={'statsBox'} sx={{position: 'absolute', top: '100%'}}>
        <Stack>
          {
            systems.map(system=>{
              return <MDBox id={'systemApp'+system.id} key={'systemApp'+system.id}>
                <ApplicabilityGraph threatTypesStats={system['threatTypesStats']}></ApplicabilityGraph>
              </MDBox>

            })
          }
        </Stack>
      </MDBox>



      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid  container={true} spacing={2} >
            <Grid item xs={6}>
              <Stack>
                <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">Standard</MDTypography>
                <ReportSwitch text={'Definition'}></ReportSwitch>
                <ReportSwitch text={'Assessment team'}></ReportSwitch>
                <ReportSwitch text={'Assessment team'}></ReportSwitch>
                <Divider></Divider>
                <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">Stuff</MDTypography>
                <ReportSwitch text={'Include company'}></ReportSwitch>
                <ReportSwitch text={'Assessment team'}></ReportSwitch>
                <ReportSwitch text={'Team roles'}></ReportSwitch>
              </Stack>
            </Grid>

            <Grid item xs={6} id={'resultOptions'}>
              <Stack>
                <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">System under consideration</MDTypography>
                <ReportSwitch text={'SuC Description'} checked={options.SysDesc} onChange={onChange} name={'SysDesc'}></ReportSwitch>
                <ReportSwitch text={'Zones'} checked={options.zones} onChange={onChange} name={'zones'}></ReportSwitch>
                <ReportSwitch text={'Conduits'}  checked={options.conduits} onChange={onChange} name={'conduits'}></ReportSwitch>
                <ReportSwitch text={'Sub-systems'}  checked={options.subSystems} onChange={onChange} name={'subSystems'}></ReportSwitch>
                <Divider></Divider>
                <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">Assessment</MDTypography>
                <ReportSwitch text={'Treatment data'} checked={options.treatmentData} onChange={onChange} name={'treatmentData'}></ReportSwitch>
                <ReportSwitch text={'Applicability measurement'} checked={options.applicabilityDist} onChange={onChange} name={'applicabilityDist'}></ReportSwitch>
                <ReportSwitch text={'Threat actors distribution'}  checked={options.actorDist} onChange={onChange} name={'actorDist'}></ReportSwitch>
                <ReportSwitch text={'Impact factor distribution'}  checked={options.cifDist} onChange={onChange} name={'cifDist'}></ReportSwitch>


              </Stack>
            </Grid>
            <Grid item xs={7}>
              <MDButton color={"info"} fullWidth  disabled={working} onClick={async () => {
                if(ready){
                  setWorking(true)
                  await renderStatGraphs()
                  await new Promise(r => setTimeout(r, data.SubSystems.length * 2 * 1000));
                  console.log(systemImages)
                  if (systemImages.length > 0) {
                    let i = 0
                    data.Rzones.forEach(zone => {
                      zone.ZsubSystems.forEach(system => {
                        system.statsGraph.source = systemImages[i]['cif']
                        system.actorGraph.source = systemImages[i]['actor']
                        system.AppGraph.source = systemImages[i]['app']

                        i = i + 1
                      })
                    })
                    console.log(data)
                    doExport().then()
                  }
                  setWorking(false)
                }else {
                  if(reportData !== null){
                    prepareTheDocument(reportData).then()
                  }
                }
              }}>
                {
                  working && <CircularProgress size={20} sx={{mr:2}} color={'white'}></CircularProgress>
                }

                {
                  ready ? 'Download' : 'Generate'
                }
              </MDButton>

              {/*<MDButton color={"info"} fullWidth  onClick={()=>{*/}
              {/*  prepareTheDocument(reportData).then()*/}
              {/*}}>Generate</MDButton>*/}
            </Grid>
            <Grid item xs={5}>
              <MDButton color={"error"} fullWidth onClick={()=>{

                setBackDrop(dispatch, false)}}>Close</MDButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item  xs={6}>
          <Grid  container={true} spacing={2}>
            <Grid item xs={12}>

              {/*<Box sx={{height: '80vh'}}>*/}
              {/*  <FileViewer*/}
              {/*    fileType={'docx'}*/}
              {/*    filePath={'ProjectReport.docx'}*/}

              {/*    onError={()=>{alert('error')}}/>*/}

              {/*</Box>*/}

            </Grid>


          </Grid>
        </Grid>
      </Grid>

    </Paper>
  )
}
export default ProjectReport
