// RAA layouts

import ProjectsPage from "layouts/ProjectsPage";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import NewProject from "layouts/RAT/components/NewProject/NewProject";
import NewAssessment from "layouts/assessment/new assessment"

// @mui icons
import Icon from "@mui/material/Icon";
import SystemDetails from "./layouts/SystemDetails/SystemDetails";
import RAT from "./layouts/RAT/ProjectsPage";
import Home from "./layouts/Home/Home";
import WorkSpaceDashboard from "./layouts/dashboard/WorkSpaceDashboard";
import WorkSpaceSelector from "./components/WorkSpaceSelctor/WorkSpaceSelctor";

const RAARoutes = [
  {
    type: "collapse",
    name: "Dash Board",
    key: "dashboardF",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Risk Assessment",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Home />,
  },
];

export default RAARoutes;
