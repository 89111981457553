import { Box } from "@mui/material";
import MDButton from "../MDButton";
import MDTypography from "../MDTypography";
function logOut(){
  localStorage.clear()
  window.location = '/'
}
const SystemOffline = ({}) => {

  return <Box>
    <MDTypography sx={{mb:2}}>The host up are connected to is  offline Currently</MDTypography>
    <Box>
      <MDButton variant={"contained"} color={"info"} sx={{mr:2}} onClick={(e)=>{window.location.reload()}}>Try again</MDButton>
      <MDButton variant={"outlined"} color={"error"} onClick={()=>{
        logOut()

      }
      }>Change Server</MDButton>
    </Box>
  </Box>
}

export default SystemOffline
