import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Projects from "../dashboard/components/Projects/Projects";
import Footer from "../../examples/Footer";
import { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { setIntroSteps, useMaterialUIController } from "../../context/theme/themeContext";

function ProjectsPage() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setIntroSteps(dispatch,      {
  //       element: "#ProjectsContainer",
  //       intro: "Find here all the Systems under consideration"
  //     })
  //   }, 3000)
  // }, [])
  return (
    <DashboardLayout>
      <MDBox py={3.5} mt={15} id={'ProjectsContainer'}>
        <MDBox>
          <Projects />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default ProjectsPage
