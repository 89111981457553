const WorkSpacesTableIntro = [
  {
    element: "#hi",
    intro: "A work space is a container that can help you manage a collection of systems  and share them with other users",
    tooltipClass: 'helpClass',
  },
  {
    element: "#addWorkSpace",
    intro: "Click Here to create a work space"
  },
  {
    element: "#workSpacesTable",
    intro: "Work spaces you manage will appear here"
  },

]

export default WorkSpacesTableIntro
