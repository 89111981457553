import { useEffect, useState } from "react";

import { Paper } from "@mui/material";
import { Chart } from "react-google-charts";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

const CharTitle = ({title, subtitle})=>{

  return(
    <MDBox display="flex" justifyContent="space-between"  pt={1} px={2} flexDirection={'column'}>
      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">{title}</MDTypography>
      <MDTypography textTransform="capitalize" color={"text"} sx={{fontSize: '14px'}}>{subtitle}</MDTypography>
    </MDBox>
  )
}

const ActorDistributionGraph = ({actorsD}) => {

  const [actorsDistribution, setActorsDistribution] = useState([ ])


  useEffect(()=>{
    if(actorsD !== undefined){
      let actors =   [["Zone", "Systems"]]
      actorsD.forEach(entry=>{
        actors.push([entry.name, entry.total])
      })
      setActorsDistribution(actors)
    }

  }, [actorsD])

  return <Chart
      chartType="PieChart"
      width="600px"
      height="400px"
      data={actorsDistribution}
      options={{ chart: {}, }}
    />

}

export default ActorDistributionGraph
