import DefaultNavbar from "../../examples/Navbars/DefaultNavbar/DefaultNavbar";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Configurator from "../../examples/Configurator";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Steps, Hints } from 'intro.js-react';
import { setBackDrop, setIntroSteps, setMiniSidenav, useMaterialUIController } from "../../context/theme/themeContext";
import { useDataController } from "../../context/data/dataContext";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useEffect, useRef, useState } from "react";
import Sidenav from "../../examples/Sidenav";
import RAARoutes from "../../RAARoutes";
import routes from "../../routes";
import ApplicationsTry from "../../components/ApplicationsTry/ApplicationsTry";
import useFetch from "../../Apis/useFetch";
import { _SystemData } from "../../_Models/_SystemData";
import { FETCH_STATUS } from "../../assets/HttpResponses";

import { setActors, setCIFs, setEntryPoints, setFRs, setStandardLS, setThreatTypesLS } from "../../assets/StorageItems";
import LoadingProvider from "../../components/LodingProvider/LoadingProvider";
import NoEnoughData from "../../components/NoEnoughData/NoEnoughData";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import _Project from "../../_Models/_Project";
import { Box, Breadcrumbs, Container } from "@mui/material";
import _WorkSpace from "../../_Models/_WorkSpace";
import WorkSpaceSelctor from "../../components/WorkSpaceSelctor/WorkSpaceSelctor";
import SystemOffline from "../../components/SystemOffline/SystemOffline";



const getRoutes = (allRoutes) => allRoutes.map((route) => {
  // if(route.type == 'parametrized'){
  //   return <Route  path={route.route}
  //                 render={(props) => {
  //     return <route.component id={props.match.params.id}/>        }}>
  //   </Route>
  // }
  if (route.collapse) {
    return getRoutes(route.collapse);
  }
  if (route.route) {
    return <Route path={route.route} element={route.component} key={route.key} />;
  }
  return null;
});


const Dashboard = ({}) => {
  const route = useLocation().pathname.split("/").slice(1);
  const [controller, dispatch] = useMaterialUIController();
  const  stepperRef = useRef(null);
  const [tutoReady, setTuToReady] = useState(false);
  const [dataController, dataDispatch] = useDataController();
  const {workSpace} = dataController
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { miniSidenav, withSideBar, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode, backDrop, applications, notifications, introSteps, introEnabled } = controller;
  const {data: systemData, status} = useFetch({loader: _SystemData.getAll, params: [], successCode: FETCH_STATUS.OK, errorMsg: 'Could not load the system data correctly'})
  const firstTimeOpen = localStorage.getItem('FIRST_TIME_OPEN')

  useEffect(()=>{
    if(systemData !== null) setStandardLS(systemData['standard'])
    if(systemData !== null) setThreatTypesLS(systemData['threatTypes'])
    if(systemData !== null) setCIFs(systemData['CIFs'])
    if(systemData !== null) setEntryPoints(systemData['entryPoints'])
    if(systemData !== null) setFRs(systemData['foundationalRequirements'])
    if(systemData !== null) setActors(systemData['actors'])
  }, [systemData])

  useEffect(()=>{
    _WorkSpace.getAll(0, 10).then(getAllOp=>{
      getAllOp.json().then(workSpaces=>{
        console.log(workSpaces)
      })
    })

  }, [firstTimeOpen])

  useEffect(()=>{
    console.clear()
    if(firstTimeOpen == null){

    }

  }, [firstTimeOpen])

  useEffect(()=>{

    if(workSpace.id == undefined){
      setBackDrop(dispatch, <WorkSpaceSelctor></WorkSpaceSelctor>)
    }
  }, [workSpace])



  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <Box sx={{minWidth: '1600px'}}>
      <LoadingProvider status={status} error={
        <Container sx={{height: '100vh', width: '100vw', display: 'flex', alignItems: 'center'}}>
          <SystemOffline></SystemOffline>
        </Container>
      }>

            <Steps
              showProgress={true}
              enabled={introEnabled}
              steps={introSteps}
              initialStep={0}
              ref={stepperRef}
              onExit={()=>{
                setIntroSteps(dispatch, [])
              }}
              onComplete={()=>{
                setIntroSteps(dispatch, [])
              }}
              options={{
                showStepNumbers: true,
                overlayOpacity: 0.7,

                doneLabel: "Got It",
                highlightClass: 'helpLayerClass',
                exitOnEsc: true

              }}
              onBeforeChange = {nextStepIndex => {
                console.log(stepperRef.current)
                stepperRef.current.updateStepElement(nextStepIndex);
            }}
            />

        <ApplicationsTry/>
        <DefaultNavbar />
      {/*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={true} />*/}

      {/*<DashboardNavbar></DashboardNavbar>*/}
      <Configurator />
      {
        withSideBar &&
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          brandName="OTIX Cyber HUB"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      }
        {
          workSpace.id !== undefined
             && <Routes id={'121313213121'}>
              {getRoutes(RAARoutes)}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            // : <NoEnoughData
            // sx={{mt: 0}}
            //   title={'You have not created any work spaces yet'}
            //   desc={<MDBox>
            //     <MDBox sx={{mb:2}}>
            //       A work space is a container that help you group a list of systems in the same view
            //     </MDBox>
            //     <MDButton variant={"gradient"} color={"info"} sx={{mr:3}}>New Work Space</MDButton>
            //     <MDButton variant={"gradient"} color={"light"}>Help</MDButton>
            //   </MDBox>}
            // ></NoEnoughData>


        }

      </LoadingProvider>
    </Box>
  )
}

export default Dashboard
