import { Container, Stack } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import { useEffect } from "react";
import { setBackDrop, useMaterialUIController } from "../../context/theme/themeContext";
import WorkSpaceSelctor from "../../components/WorkSpaceSelctor/WorkSpaceSelctor";
import { useDataController } from "../../context/data/dataContext";

const Home = ({}) => {
  const [dataController, dataDispatch] = useDataController();
  const [controller, dispatch] = useMaterialUIController();
  const {workSpace} = dataController
  useEffect(()=>{

    if(workSpace.id == undefined){
      setBackDrop(dispatch, <WorkSpaceSelctor></WorkSpaceSelctor>)
    }else {
      window.location = '/'+workSpace.name +'/systems'
    }
  }, [workSpace])

  return <Container sx={{pt:'10%'}}>
    <Stack>
      <MDTypography fontWeight={'bold'}> Welcome</MDTypography>
      <MDTypography> You are working on project Home</MDTypography>
      <MDBox>Project Id: 12122</MDBox>
    </Stack>

  </Container>
}

export default Home
