const FETCH_STATUS = {
  OK: '200',
  POSTED: '201',
  NOT_FOUND: '201',
  NOt_FOUND: '201',
  OFFLINE: '000',
  SERVER_OFFLINE: '-1',
}
export {
  FETCH_STATUS
}
