const ExpandedProjectIntro = [
  {
    element: '#ProjectTabs',
    intro: 'current system parts management tabs'
  },
  {
    element: "#ProjectDetailsTab",
    intro: "Find here all the information about the system"
  },
  {
    element: "#ProjectZonesTab",
    intro: "Every system must be dispatched to zones"
  },
  {
    element: "#ProjectConuitsTab",
    intro: "Conduits are what link a zone to an other"
  },
  {
    element: "#ProjectSubSystemsTab",
    intro: "Sub-systems management"
  },

]

export default ExpandedProjectIntro
