import { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import wave from "../../../../assets/wave.svg";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ProjectsRow from "./ProjectsRow";
import * as React from "react";
import _Project from "../../../../_Models/_Project";
import { Progress, Project, EditProject } from "../../../ProjectsPage/data/projectsTableData";
import team2 from "../../../../assets/images/team-2.jpg";
import MDBadge from "../../../../components/MDBadge";
import {
  Button,
  Container,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  Stack,
  TableHead,
} from "@mui/material";
import Link from "@mui/material/Link";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {
  setBackDrop,
  setIntroSteps,
  setRenderer,
  useMaterialUIController,
} from "../../../../context/theme/themeContext";
import Box from "@mui/material/Box";
import NewProject from "../../../RAT/components/NewProject/NewProject";
import { setProjectList, useDataController } from "../../../../context/data/dataContext";
import DataTableHeadCell from "../../../../examples/Tables/DataTable/DataTableHeadCell";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import RAARoutes from "../../../../RAARoutes";
import NoEnoughData from "../../../../components/NoEnoughData/NoEnoughData";
import Intro from "../../../../Intro/Intro";
import Checkbox from "@mui/material/Checkbox";
const columns = [
  { label: '', align: 'left' },
  { label: 'Name', },
  { label: 'users', },
  { label: 'Systems', },
  { label: 'Zones', },
  { label: 'Assessment', },
  { label: 'Treatment', },
  { label: 'Created At', },
  { label: 'Actions ', align: 'right' },

]


const Welcome = ({}) => {
  const [controller, dispatch] = useMaterialUIController();


  useEffect(()=>{

      if(localStorage.getItem('TopNavIntro') == null) {
      // if(true) {

          setTimeout(()=>{
            setIntroSteps(dispatch, Intro.TopNav)
          }, 200)
      }
    localStorage.setItem('TopNavIntro', 'true')


  }, [])
  return (
    <MDBox sx={{height: '77vh',}}>
      <svg viewBox="0 0 1440 320" style={{position: "absolute", bottom: 0, right: '10px'}}>
        <defs>
          <clipPath id="shape">
            <path
              fill="#0099ff" fill-opacity="1"
              d="M0,288L40,245.3C80,203,160,117,240,85.3C320,53,400,75,480,106.7C560,139,640,181,720,202.7C800,224,880,224,960,197.3C1040,171,1120,117,1200,85.3C1280,53,1360,43,1400,37.3L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"/>
          </clipPath>

        </defs>
        <image width="1800" height="1200" clip-path="url(#shape)"  href="https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?cs=srgb&dl=pexels-pixabay-373543.jpg&fm=jpg" >
        </image>
      </svg>
      <Container sx={{background: wave}}>
        <MDBox sx={{display: 'flex', alignItems: 'center'}}>
          <Stack spacing={2}>
            <MDTypography as={'h1'}>.& Welcome</MDTypography>
            <MDBox sx={{width:'60%'}}>
              <MDTypography>Congratulations, your workspace is correctly set up, you can start by adding a system or invite your team members to this work space</MDTypography>

            </MDBox>
            <br/>
            <br/>
            <MDBox sx={{display: 'flex', alignItems: 'flex-end'}}>
              <MDButton color={"info"} sx={{mr:2}} variant={"outlined"} size={"small"} onClick={() => {setBackDrop(dispatch, <Box sx={{ pl: '30vw', mt: '20vh' }}><NewProject></NewProject></Box>)}}>
                <Icon sx={{mr:1}}>add</Icon>
                New System
              </MDButton>

              <MDButton color={"primary"} variant={"outlined"} sx={{mr:2, display: 'flex', alignItems: 'center'}} size={"small"}>
                <Icon sx={{mr:1}}>double_arrow</Icon>add colaborators
              </MDButton>
              <MDButton color={"info"} variant={"contained"} >
                <Icon sx={{mr:1}}>help</Icon>
                New User guide
              </MDButton>
            </MDBox>


            {/*<MDBox sx={{mt:60}}>*/}
            {/*  <MDButton color={"info"} variant={"outlined"} size={"small"}>Help Center</MDButton>*/}
            {/*</MDBox>*/}

          </Stack>
        </MDBox>
      </Container>

    </MDBox>
 )
}
function Projects() {

  const [controller, dispatch] = useMaterialUIController();
  const [render, setRender] = useState(false);
  const {renderer} = controller
  const [dataController, dataDispatch] = useDataController();
  let { workSpace, projectList } = dataController
  // const { columns, rows } = data();
  const [menu, setMenu] = useState(null);
  const [rows, setRows] = useState([]);
  const [dialState, setDialState] = useState(localStorage.getItem('dialState') ==  null);
  const { projectName  } = useParams()

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  async function loadProjects() {
    try {
      let getProjects = await _Project.getAll(workSpace.id, 0, 100)
      if (getProjects.status == '200') {

        let projects = await getProjects.json()
        setProjectList(dataDispatch, projects)
      } else {
        console.log(getProjects)
        console.log('failed downloading projects list')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect( () => {
    loadProjects().then()
  }, [renderer.projects, workSpace])



  useEffect( () => {
    if (projectList) {
       rowRender(projectList)
    }

  }, [projectList]);
  const rowRender = (projects) => {
    const rows = projects.map((project, index) => {
      return (
        {
          label: project.name,
          id: project.id,
          name: <Project image={project.image} name={project.name} email={project.description} />,
          editProject: <EditProject name={project.name} email="Siram project description" />,
          pipes: (
            <MDBox ml={-1}>
              <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
            </MDBox>
          ),
          action: (
            <Button component="a" href="#" variant="caption" color="text" fontWeight="medium">
              <Link href={'/ProjectDetails/' + project.id}>Details</Link>
            </Button>
          ),
          assessment: <Progress color="info" value={80} />,
          treatment: <Progress color="info" value={40} />,
          systems: project['_count'].systems,
          zones: project['_count'].zones,
          createdAt: project['createdAt']
        }
      )
    })
    setRows(rows)
    setRender(!render)
  }


  useEffect(()=>{
    if(rows.length > 0){
      if(projectName == undefined){
        if(localStorage.getItem('ProjectsIntro') == null) {
        // if(true) {

          setTimeout(()=>{
            setIntroSteps(dispatch, Intro.ProjectsTable)
          }, 200)
        }
      }
      else {
        if(localStorage.getItem('ProjectExpandedIntro') == null) {
          setTimeout(()=>{
            setIntroSteps(dispatch,Intro.ExpandedProject)
            localStorage.setItem('ProjectExpandedIntro', 'true')
          }, 200)
        }

      }

      localStorage.setItem('ProjectsIntro', 'true')
    }
  }, [projectName, rows])

  return (

    <>
      <Dialog
        open={false}
        onClose={()=>{}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"New user guide notification"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The system can push you tooltips to walk you throw the application as you use it , you can turn it On or OFF from the config panel later


          </DialogContentText>
          <Box sx={{width: '100%', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'right', mt:5}}>

          <FormControlLabel control={<Checkbox defaultChecked />} label="Dont show again"/>
          </Box>
        </DialogContent>
        <DialogActions>
          <MDButton color={"info"} onClick={()=>{
            setDialState(false)
            localStorage.setItem('dialState', 'false')
          }}>I Like it</MDButton>
          <MDButton onClick={(e)=>{
            localStorage.setItem('GlobalIntro', 'false')
            localStorage.setItem('dialState', 'false')
            setDialState(false)}}  variant={"outlined"} color={"error"}>
            Turn OFF
          </MDButton>
        </DialogActions>
      </Dialog>
      {/*<MDBox sx={{mb:10}}>*/}
      {/*  <Stack>*/}
      {/*    <MDTypography fontWeight={'bold'}> Welcome</MDTypography>*/}
      {/*    <MDTypography> You are working on project Home</MDTypography>*/}
      {/*    <MDBox>Project Id: 12122</MDBox>*/}
      {/*  </Stack>*/}
      {/*</MDBox>*/}


      {

        projectList != undefined && projectList.length > 0
          ?  <Card>
            <MDBox
              display={'flex'} alignItems={'center'} justifyContent={'space-between'}
              mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info"
              borderRadius="lg" ZZcoloredShadow="info"
            >
              <MDTypography variant="h6" color="white" id={'tttt'}>Systems under control</MDTypography>
              <MDButton variant="gradient" color="dark" onClick={() => {setBackDrop(dispatch, <Box sx={{ pl: '30vw', mt: '20vh' }}><NewProject></NewProject></Box>)}}>
                <Icon sx={{ fontWeight: "bold" }}>library_add </Icon>
                &nbsp;add new system
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '' }}>
                      {columns.map((column, index) => (
                        <DataTableHeadCell
                          key={'header' + index}
                          {...column}

                        >
                          {column.label}
                        </DataTableHeadCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <ProjectsRow key={row.id} project={row} rowRender={rowRender} isOpen={false}/>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          </Card>
          :<Welcome></Welcome>
          // : <NoEnoughData
          //   sx={{mt: 0}}
          //   title={'You have not created any work spaces yet'}
          //   desc={<MDBox>
          //     <MDBox sx={{mb:2}}>
          //       A work space is a container that help you group a list of systems in the same view
          //     </MDBox>
          //     <MDButton variant={"gradient"} color={"info"} sx={{mr:3}}  onClick={() => {setBackDrop(dispatch, <Box sx={{ pl: '30vw', mt: '20vh' }}><NewProject></NewProject></Box>)}}>New System</MDButton>
          //     <MDButton variant={"gradient"} color={"light"}>Help</MDButton>
          //   </MDBox>}
          // ></NoEnoughData>




      }
    </>




  );
}

export default Projects;
