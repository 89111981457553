import JSZip from "jszip";

async function ImageReplacer(doc, target, newImage) {
  const new_zip = new JSZip()
  await new_zip.loadAsync(doc)

  // Replace the image
  const target_path = target
  new_zip.remove(target_path)
  new_zip.file(target_path, newImage, { binary: true })

  // Produce an output buffer
  const blob = await new_zip.generateAsync({ type: "blob" })
  return blob
}

export default ImageReplacer
