import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// RAA components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import _User from "../../../_Models/_User";
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { PushNotification, useMaterialUIController } from "../../../context/theme/themeContext";
import Box from "@mui/material/Box";



const SingInPage = (props) => {
  const [rememberMe, setRememberMe] = useState(false);
  const [controller, dispatch] = useMaterialUIController();

  const handleSetRememberMe = () => {setRememberMe(!rememberMe)}
  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState();
  const [isError, setIsError] = useState(false)
  const [serverError, setServerError] = useState(false)
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await _User.login({mail: username, password: password})
      console.log(response)
      switch (response.status){
        case 201:
          let result  = await response.json()
          localStorage.setItem('user', JSON.stringify(result['user']))
          localStorage.setItem("userToken",result['access_token']);
          window.location = '/'
          break;
        case 404:
          PushNotification(dispatch, 'Wrong user name or password', 'error', 3000)
          setIsError(true)
          setTimeout(()=>{setIsError(false)}, 2500)
          break;
        case 500:
          PushNotification(dispatch, 'Service not available currently please try later', 'error', 3000)
          break;
        default:
          setServerError(true)
          setTimeout(()=>{setServerError(false)}, 2500)
          PushNotification(dispatch, 'The server you are trying to reach is not available', 'error', 3000, )

      }
    }catch (error){

    }
  };

  return (
      <Box sx={{width: '100vw', height: '100vh', display: 'flex', overflow: 'hidden'}}>
        <Box
          sx={{
            width: [0, 0, '50%'],  display: ['none', 'none', 'flex', 'flex', 'flex', 'flex', 'flex'], alignItems: 'center', justifyContent: 'center',
            height: [0, 0, '100%']
        }}
        >
          <Stack>
            <MDTypography as={'h1'}>OTICS Technologies</MDTypography>
            <MDTypography>Digital transformation made easy</MDTypography>
          </Stack>
        </Box>
        <Card sx={{width: ['100%', '100%', '50%'], p:[1, 1, 4], display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <MDBox
            sx={{width: '100%'}}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
              <MDTypography variant="h4" fontWeight="medium" color="white" >
                OTICS ARA Login
              </MDTypography>
          </MDBox>
          <MDBox method="post" onSubmit={handleLogin} pt={4} pb={3}  sx={{width: '100%'}}>
            <FormControl fullWidth={true}>
              <InputLabel>Server</InputLabel>
              <Select
                error={serverError}
                type="text"
                name="server"
                label="Server"
                onChange={(e) => {
                  switch(e.target.value){
                    case 0:
                      localStorage.setItem('SERVER', 'https://backend.otics.net/')
                      break;
                    case 1:
                      localStorage.setItem('SERVER', 'http://localhost:3000/')
                      break;


                  }
                }}
                fullWidth
              >
                <MenuItem value={0}>OTICS Primary server</MenuItem>
                <MenuItem value={1}>Local</MenuItem>
              </Select>
            </FormControl>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  error={isError}
                  type="text"
                  name="username"
                  label="User Name"
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  helperText={username === "" ? 'Empty field!' : ' '}
                />
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  error={isError}
                  type="password"
                  name="password"
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <Grid container={true}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <MDButton type="submit" variant="gradient" color="info" fullWidth>
                    sign in
                  </MDButton>
                </Grid>

              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </Box>
  );
};

export default SingInPage;
