import { useEffect, useState } from "react";

import { Paper } from "@mui/material";
import { Chart } from "react-google-charts";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

const CharTitle = ({title, subtitle})=>{

  return(
    <MDBox display="flex" justifyContent="space-between"  pt={1} px={2} flexDirection={'column'}>
      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">{title}</MDTypography>
      <MDTypography textTransform="capitalize" color={"text"} sx={{fontSize: '14px'}}>{subtitle}</MDTypography>
    </MDBox>
  )
}

const ApplicabilityGraph = ({threatTypesStats}) => {

  const [applicabilityDistribution, setApplicabilityDistribution] = useState({old: [], new: []})




  useEffect(()=>{
    if(threatTypesStats !== null){
      let data1 = [["Name", "Applicable"]]
      let data2 = [["Dinosaur", "Applicable"]]
      threatTypesStats.forEach(entry=>{
        data1.push([entry.name, entry['applicable'] + entry['notApplicable']])
        data2.push([entry.name, entry['applicable']])
      })
      setApplicabilityDistribution({old: data1, new: data2})
    }

  }, [threatTypesStats])

  return      <Chart
    chartType="ColumnChart"
    width="100%"
    height="400px"
    diffdata={applicabilityDistribution}
    options={{
      legend: {old: 'jhkjhkj'}
    }}
  />

}

export default ApplicabilityGraph
