const NewProjectIntro = [
  {
    element: "#NewProjectContainerr",
    intro: "This Window let you create a new System",


  },
  {
    element: "#ProjectName",
    intro: "The System you choose here will be used to distinguish ties system and will be exported in the final report",
    tooltipClass: 'helpClass',
  },
  {
    element: "#ProjectDesc",
    intro: "A short text to describe the system under considerations, it will be exported in the report.",
    tooltipClass: 'helpClass',
  },


]

export default NewProjectIntro
