import RequestHandler from "../Apis/RequestHandler";
import { PushNotification } from "../context/theme/themeContext";
const userToken = localStorage.getItem('userToken')
const model = 'workSpace/'

const _Project = {
  create: async (data) => {
    const createResult = await RequestHandler.POST(model, data)
    return createResult
  },
  getAll: async (skip, take) => {
    const getAllResult = await RequestHandler.GET('workSpace?skip=' + skip + '&take=' + take, null)
    return getAllResult
  },


}

export default _Project
