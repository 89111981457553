import MDBox from "../components/MDBox";
import React from 'react'
import MDButton from "../components/MDButton";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import TopNavIntro from "./TopNav";
import ProjectsTableIntro from "./ProjectsTableIntro";
import ExpandedProjectIntro from "./ExpandedProject";
import WorkSpacesTableIntro from "./WorkSpacesTableIntro";

const Comp = ({})=>{
  return React.cloneElement(<Box sx={{width: '50vw'}}>
    <Typography>The system can push you tooltips just like this one while using the application, you can turn it On or OFF from the config panel later </Typography>

    <Box sx={{width: '100%', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
      {/*<FormControlLabel control={<Checkbox defaultChecked />} label="Dont show again"/>*/}
      <Button variant={'outlined'} sx={{mr: 2}} size={'small'}>Sounds good</Button>
      <Button variant={'outlined'} color={'error'}  size={'small'}>Turn OFF</Button>
    </Box>
    </Box>)

}

const Intro = {
  TopNav : TopNavIntro,

  ProjectsTable: ProjectsTableIntro,


  ExpandedProject: ExpandedProjectIntro,

  WorkSpacesTable: WorkSpacesTableIntro

}

export default Intro


